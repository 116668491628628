var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',_vm._b({},'SwContainer',{
        stretched: _vm.config.full_height,
        paddingY: _vm.config.block_padding_y,
        bgColor: _vm.config.type_background === 'color'
            ? _vm.config.background_color
            : _vm.config.background_image_color,
        bgImage: _vm.config.background_url_image,
        bgOpacity: _vm.config.type_background === 'color'
            ? _vm.config.background_opacity
            : _vm.config.background_image_opacity,
    },false),[(_vm.styleKey === _vm.VARIANTS.TEXT_IN_ACCORDION)?_c('TextInAccordion',{attrs:{"title":_vm.config.heading_text,"content":_vm.config.drop_down_list_items,"inverted":_vm.config.inverted_font_color}}):(_vm.styleKey === _vm.VARIANTS.TEXT_IN_COLUMNS)?_c('TextInColumns',{attrs:{"title":_vm.config.heading_text,"content":_vm.config.content_location_for_text === 'text_in_two_columns'
            ? [_vm.config.text_text, _vm.config.text_two_text]
            : [_vm.config.text_text],"centered":_vm.config.content_location_for_text === 'text_center',"inverted":_vm.config.inverted_font_color}}):_c('TextOnImage',{attrs:{"title":_vm.config.heading_text,"text":_vm.config.text_text,"position":_vm.position,"inverted":_vm.config.inverted_font_color}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }