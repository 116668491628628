<template>
    <SwContainer
        v-bind="{
            stretched: config.full_height,
            paddingY: config.block_padding_y,
            bgColor: config.type_background === 'color'
                ? config.background_color
                : config.background_image_color,
            bgImage: config.background_url_image,
            bgOpacity: config.type_background === 'color'
                ? config.background_opacity
                : config.background_image_opacity,
        }"
    >
        <TextInAccordion
            v-if="styleKey === VARIANTS.TEXT_IN_ACCORDION"
            :title="config.heading_text"
            :content="config.drop_down_list_items"
            :inverted="config.inverted_font_color"
        />

        <TextInColumns
            v-else-if="styleKey === VARIANTS.TEXT_IN_COLUMNS"
            :title="config.heading_text"
            :content="config.content_location_for_text === 'text_in_two_columns'
                ? [config.text_text, config.text_two_text]
                : [config.text_text]
            "
            :centered="config.content_location_for_text === 'text_center'"
            :inverted="config.inverted_font_color"
        />

        <TextOnImage
            v-else
            :title="config.heading_text"
            :text="config.text_text"
            :position="position"
            :inverted="config.inverted_font_color"
        />
    </SwContainer>
</template>

<script>
const VARIANTS = {
  TEXT_IN_ACCORDION: 'drop_down_list',
  TEXT_IN_COLUMNS: 'text',
  TEXT_ON_IMAGE: 'text_with_background'
}

export default {
  name: 'WebBlockTextContainer',

  components: {
    TextInAccordion: () => import('@@/website/components/blocks/BlockText/TextInAccordion'),
    TextInColumns: () => import('@@/website/components/blocks/BlockText/TextInColumns'),
    TextOnImage: () => import('@@/website/components/blocks/BlockText/TextOnImage')
  },

  props: {
    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    VARIANTS: () => VARIANTS,

    position () {
      switch (this.config.content_location_for_text_with_background) {
        case 'text_with_background_right':
          return 'right'
        case 'text_with_background_center':
          return 'center'
        default:
          return 'left'
      }
    }
  }
}
</script>
